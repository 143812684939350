footer {
    /* position: absolute;
    left: 0;
    bottom: 0;
    margin-top: 78px; */
    /* width: 100%; */
    background-color: lightgrey;
    /* padding: 15px 0; */
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
}
.footer {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.name {
    display: flex;
    gap: 4px;
}
