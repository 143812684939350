.pin-image {
  height: 500px;
  object-fit: contain;
  /* background-color: blueviolet; */
}

@media (max-width: 468px) {
  .pins-template {
    width: 80%;
    /* background-color: aquamarine; */
  }
  .pin-image {
    height: 300px;
    object-fit: contain;
  }
}

.image {
  width: 20px;
  height: 20px;
}

.pins-template {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-left: 8%;
  align-items: center;
}

.digiimg {
  height: 30px;
  width: 30px;
}
