.filter-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.pintext-uid {
  color: #c0c0c0;
}

.pin-token {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pin-token img {
  width: 20px;
  height: 20px;
}

.pin-image {
  height: 200px;
  object-fit: contain;
}

.pins-template {
  /* background-color: rgb(215, 97, 97); */
  width: 85%;
}
.wedimg {
  width: 30px;
  height: 30px;
}

@media (max-width: 900px) {
  .pin-image {
    width: 250px;
    max-height: 300px;
  }
}

@media (max-width: 768px) {
  .pins-template {
    width: 60%;
    /* background-color: bisque; */
  }
}

@media (max-width: 468px) {
  .pins-template {
    width: 80%;
    /* background-color: aquamarine; */
  }
  .pin-image {
    height: 300px;
    object-fit: contain;
  }
}
