.container-70 {
    max-width: 100%;
}

.collectpinstemplate {
    display: grid;
    grid-template-columns: 95% 5%;
    grid-template-areas: "title ." "acceptpin ." "description ." "eventdate ." "properties ." "nft .";
    row-gap: 25px;
    margin-bottom: 25px;
}

.pin-image {
    grid-area: image;
}

.acceptpin {
    grid-area: acceptpin;
    align-self: center !important;
}

.title {
    grid-area: title;
}

.description {
    grid-area: description;
}

.eventdate {
    grid-area: eventdate;
}

.properties {
    grid-area: properties;
}

.nft {
    grid-area: nft;
}

@media (max-width: 468px) {
    .pin-image {
        width: 100%;
        max-height: 300px;
    }
    .container-70 {
        max-width: 95%;
    }

    .collectpinstemplate {
        grid-template-columns: 1fr;
        grid-template-areas: "title" "acceptpin" "eventdate" "description" "properties" "nft";
    }
}
