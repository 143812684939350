.img {
  width: 30px;
  height: 30px;
}

.active-navlink {
  background: #ff8b3d !important;
  color: white !important;
  border-radius: 2rem !important;
  display: inline block !important;
}

.top-popup {
  width: 50%;
  margin-left: 20px;
}

@media (max-width: 900px) {
  .top-popup {
    width: 80%;
    margin: auto;
  }
}
