.verify-pins {
    height: 400px;
    object-fit: contain;
}

.ownership {
    display: flex;
    align-items: center;
    /* width: 20%; */
    gap: 25px;
    /* margin: 0 auto; */
    justify-content: center;
}