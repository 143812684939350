.login {
    min-height: 100vh;
}

.bg-image {
    background-image: url("https://source.unsplash.com/WEQbe2jBg40/600x1200");
    background-size: cover;
    background-position: center;
}

.login-heading {
    font-weight: 300;
}

.btn-login {
    font-size: 0.9rem;
    letter-spacing: 0.05rem;
    padding: 0.75rem 1rem;
}

.pages {
    overflow: auto;
    display: grid;
    grid-template-columns: 1fr;
    overflow-x: hidden;
}

.route {
    min-height: 80vh;
}

html, body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}